import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import logoWhite from "../images/next-you-logo-head.png";
import img404 from "../images/404.png";

import { Helmet } from "react-helmet"

import "./../components/ErrorPage.css";


function NotFoundPage() {
    const [languageGreek, setLanguageGreek] = useState(false)

    useEffect(() => {
        var userLang = navigator.language || navigator.userLanguage;
        if (userLang.toLowerCase().includes("el")) {
            setLanguageGreek(true)
        } else {
            setLanguageGreek(false)
        }
    }, [])
    return (
        <div>
            <Helmet>
                <title>404 | Not Found</title>
                <meta name="robots" content="noindex" />
            </Helmet>

            <div>
                <Link to={"/"} className="py-6 block">
                    <img className="w-56 md:w-56 m-auto" src={logoWhite} alt="white-logo" />
                </Link>
            </div>
            <div className="error-page-container">
                <div className="flex flex-wrap justify-center">
                    <img className="w-56 md:w-64 m-auto" src={img404} alt="404" />
                    <React.Fragment>
                        <h1 className="py-8 text-xl w-full text-center" style={{ color: "#609a33" }}>ΩΧΧΧ! Η ΣΕΛΙΔΑ ΠΟΥ ΠΡΟΣΠΑΘΕIΤE ΝΑ ΔΕΙΤΕ ΔΕΝ ΜΠΟΡΕΙ ΝΑ ΒΡΕΘΕΙ.</h1>
                        <Link to={"/"} className="tab-bttn mt-4 md:mt-0" style={{ width: "auto", height: "auto" }} >
                            Επιστροφή στην Αρχική
                        </Link>
                    </React.Fragment>
                </div>
            </div>
        </div>
    )
}


export default NotFoundPage
